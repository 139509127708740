import moment, { Moment } from 'moment';
import { GraphGroupName } from '@common-src/model/enum';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { post } from '@common-src/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import { CustomerFlowTypeList } from './business-area-realtime-customer';

export class BusinessFloorRealtimeCustomerQueryModel extends QueryModel {
    @QueryControl({
        label: '楼层选择',
        type: QueryControlType.TREE_SELECT,
        selectChildren: false,
        mode: 'multiple',
        optionsPromise: ReportService.getLocationTree,
        optionsPromiseParam: GraphGroupName.CUSTOMER_FLOW_FLOOR,
        index: 1,
        defaultValue: '[0].children',
        required: true,
        onlySelectLeaf: true
    })
    floor: string = undefined;
    floorObj: any = undefined;

    @QueryControl({
        label: '日期选择',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        required: true,
        isRange: false
    })
    date: Moment = moment();

    @QueryControl({
        label: '属性',
        type: QueryControlType.SELECT,
        options: CustomerFlowTypeList,
        span: 8,
        required: true
    })
    customerFlowType: string = 'inCount';

    toService() {
        return {
            groups: _.map(this.floor, item => _.get(item.split('|'), '[1]')),
            startTime: this.date.startOf('day').toDate().getTime(),
            endTime: this.date.clone().add(1, 'day').startOf('day').toDate().getTime(),
            sum: false
        };
    }
}

// export async function BusinessFloorRealtimeCustomerService(model: BusinessFloorRealtimeCustomerQueryModel) {
//     const url = `${REPORT_URL_PATH}/customerFlow/trend/daily`;
//     const params = model.toService();
//     const res = await post(url, params);
//     const chartOptions = {
//         data: {
//             columns: ['name', 'value'],
//             rows: _.map(res, item => {
//                 return {
//                     name: item.name,
//                     value: _.sumBy(item.flowData, fd => fd[model.customerFlowType])
//                 };
//             })
//         },
//         settings: {
//             type: 'ring',
//             radius: ['25%', '65%'],
//             offsetY: '50%'
//         },
//         extend: {
//             series: {
//                 label: {
//                     show: true,
//                     normal: {
//                         formatter: '{d|{d}%}\n{b|{b}} {c|{c}}', // 图形外文字上下显示
//                         padding: [0, -50], // 文字和图的边距
//                         rich: {
//                             b: { // name 文字样式
//                                 fontSize: 12,
//                                 lineHeight: 20
//                             },
//                             c: { // value 文字样式
//                                 fontSize: 12,
//                                 lineHeight: 20
//                             },
//                             d: {
//                                 lineHeight: 25,
//                                 verticalAlign: 'center',
//                                 align: 'left'

//                             }
//                         }
//                     }
//                 },
//                 labelLine: {
//                     length: 60,
//                     length2: 60
//                 }
//             }
//         }
//     };
//     return assignDefaultExtend(chartOptions);
// }
