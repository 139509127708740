import { Moment } from 'moment';
import { GraphGroupName } from '@common-src/model/enum';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { post } from '@common-src/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';

export class DhcRealtimeBreakdownQueryModel extends QueryModel {
    @QueryControl({
        label: '专业选择',
        type: QueryControlType.SELECT,
        selectChildren: false,
        optionsPromise: ReportService.getLocationList,
        optionsPromiseParam: GraphGroupName.DHC_PROFESSION,
        index: 1,
        span: 8,
        defaultValue: '[0].value',
        require: true
    })
    groupId: string = undefined;
    toService() {
        return {
            groupId: this.groupId
        };
    }
}

export async function DhcRealtimeBreakdownService(model: DhcRealtimeBreakdownQueryModel) {
    const url = `${REPORT_URL_PATH}/equipment/dhc/failureCount`;
    const params = model.toService();
    const rows:any = await post(url, params);
    // const rows: any = await getReportFailuerDHCJsonData();
    const chartOptions = {
        data: {
            columns: ['name', 'failureCount'],
            rows
        },
        settings: {
            type: 'histogram',
            labelMap: {
                failureCount: '损坏设备'
            }
        },
        extend: {
            yAxis: {
                name: '单位：台',
                position: 'left',
                axisLine: {
                    show: true
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                }
            }
        }
    };
    return assignDefaultExtend(chartOptions);
}
