import { GraphGroupName } from '@common-src/model/enum';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { post } from '@common-src/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';

export class CameraFloorDeviceQueryModel extends QueryModel {
    @QueryControl({
        label: '楼层选择',
        type: QueryControlType.TREE_SELECT,
        selectChildren: false,
        mode: 'multiple',
        optionsPromise: ReportService.getLocationTree,
        optionsPromiseParam: GraphGroupName.VIDEO_FLOOR,
        index: 1,
        span: 16,
        defaultValue: '[0].children',
        required: true,
        onlySelectLeaf: true
    })
    floor: string = undefined;
    floorObj: any = undefined;

    toService() {
        return {
            areas: _.uniq(_.map(this.floor, item => _.get(item.split('|'), '[0]'))),
            floor: _.map(this.floor, item => _.get(item.split('|'), '[1]'))
        };
    }
}

export async function CameraFloorDeviceService(model: CameraFloorDeviceQueryModel) {
    const url = `${REPORT_URL_PATH}/camera/count`;
    const params = model.toService();
    const res = await post(url, params);
    const chartOptions = {
        data: {
            columns: ['name', 'offlineCount', 'onlineCount'],
            rows: res
        },
        settings: {
            type: 'histogram',
            labelMap: {
                offlineCount: '问题设备',
                onlineCount: '在线设备'
            }
        }
    };
    return assignDefaultExtend(chartOptions);
}
