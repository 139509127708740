import { Moment } from 'moment';
import { GraphGroupName } from '@common-src/model/enum';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { post } from '@common-src/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import { CustomerFlowTypeList } from './business-area-realtime-customer';

export class BusinessFloorDayCustomerQueryModel extends QueryModel {
    @QueryControl({
        label: '楼层选择',
        type: QueryControlType.TREE_SELECT,
        selectChildren: false,
        mode: 'multiple',
        optionsPromise: ReportService.getLocationTree,
        optionsPromiseParam: GraphGroupName.CUSTOMER_FLOW_FLOOR,
        index: 1,
        defaultValue: '[0].children',
        required: true,
        span: 8,
        onlySelectLeaf: true
    })
    floor: string = undefined;
    floorObj: any = undefined;

    @QueryControl({
        label: '日期范围',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_7_DAY();

    @QueryControl({
        label: '属性',
        type: QueryControlType.SELECT,
        options: CustomerFlowTypeList,
        span: 8,
        required: true
    })
    customerFlowType: string = 'inCount';

    @QueryControl({
        label: ' ',
        type: QueryControlType.RADIO_GROUP,
        options: [{ name: '合并', value: true },
            { name: '不合并', value: false }],
        span: 8
    })
    sum: boolean= false;

    toService() {
        if (this.dateRange && this.dateRange.length === 2) {
            return {
                groups: _.map(this.floor, item => _.get(item.split('|'), '[1]')),
                startTime: this.dateRange[0].startOf('day').toDate().getTime(),
                endTime: this.dateRange[1].clone().add(1, 'day').startOf('day').toDate().getTime(),
                sum: this.sum
            };
        }
    }
}
