import moment from 'moment';
import { GraphGroupName } from '@common-src/model/enum';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { post } from '@common-src/service/request';
import ReportService, { REPORT_URL_PATH, assignDefaultExtend, timeTransformData } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import { ParkingTypeList } from './parking-area-hour-trend';

export class ParkingFloorRealtimeQueryModel extends QueryModel {
    @QueryControl({
        label: '楼层选择',
        type: QueryControlType.TREE_SELECT,
        selectChildren: false,
        mode: 'multiple',
        optionsPromise: ReportService.getLocationTree,
        optionsPromiseParam: GraphGroupName.CAR_PACKING_FLOOR,
        index: 1,
        defaultValue: '[0].children',
        required: true,
        onlySelectLeaf: true
    })
    floor: string = undefined;
    floorObj: any = undefined;

    @QueryControl({
        label: '属性',
        type: QueryControlType.SELECT,
        options: ParkingTypeList,
        span: 8,
        required: true
    })
    parkingType: string = 'freeCount';

    @QueryControl({
        label: ' ',
        type: QueryControlType.RADIO_GROUP,
        options: [{ name: '合并', value: true },
            { name: '不合并', value: false }],
        span: 8
    })
    sum: boolean= false;

    toService() {
        return {
            areas: _.uniq(_.map(this.floor, item => _.get(item.split('|'), '[0]'))),
            floor: _.map(this.floor, item => _.get(item.split('|'), '[1]')),
            startTime: moment().startOf('day').toDate().getTime(),
            endTime: moment().add(1, 'day').startOf('day').toDate().getTime(),
            sum: this.sum
        };
    }
}

export async function ParkingFloorRealtimeService(model: ParkingFloorRealtimeQueryModel) {
    const url = `${REPORT_URL_PATH}/parking/trend`;
    const params = model.toService();
    const res = await post(url, params);
    const parkingType = _.find(ParkingTypeList, item => item.value === model.parkingType);
    // { code: 'rate', name: '占有率' }
    const chartOptions = {
        data: timeTransformData(res, [{ code: 'timestamp', name: '小时' }, { code: parkingType.value, name: parkingType.name }], 'MM-DD'),
        settings: {
            type: 'histogram',
            yAxisType: parkingType.value === 'rate' ? ['percent'] : ['KMB']
        }
    };
    return assignDefaultExtend(chartOptions);
}
